// Required JS from local files
import { Context, addInitialFunction, dfmAlert } from './dfm5';
import { posthogEventCoursesBrowseClicked } from '~/utils/posthog/courses';

// CSS
import './homepage-new2.css';

$(function(){
   $("#homepage-watch-video").on("click", () => {
      dfmAlert(`
         <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/KJT1fti5lwU"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      `);
   })

   $("#homepage-browse-courses-button").on("click", () => {
      posthogEventCoursesBrowseClicked({ triggerId: "homepage-browse-courses-button" });
   });

   addInitialFunction(function(){
      if (Context.user) {
         console.log("Redirecting to dashboard...");
         window.location = 'dashboard.php';
      }
   });
});